import { useEffect } from "react";
import PropTypes from 'prop-types'
// import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  // const location = useLocation();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return <>
    {props.children}
  </>
};

ScrollToTop.propTypes = {
  children: PropTypes.node, // This line validates the 'children' prop
};

export default ScrollToTop;
